import React, { useState, useEffect } from "react";
import Head from "next/head";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { getProviders, signIn, getSession, getCsrfToken, useSession } from "next-auth/react";
import SocialLoginButton from "../sharedcomponents/SocialLoginButton";
import config from "../config";
import { gtagRaw } from "../utils/ga4";
import qs from "qs";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import * as Sentry from "@sentry/nextjs";
import { parse, serialize } from "cookie";

const textInputStyle =
  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
const textInputErrorStyle =
  "shadow appearance-none border border-orange-dark rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

function signin({ providers, csrfToken, loginDisabled, accessToken, userid }) {
  const [serverErrorLogin, setServerErrorLogin] = useState(null);
  const [serverErrorTotp, setServerErrorTotp] = useState(null);
  //const [serverErrorSignup, setServerErrorSignup] = useState(null);
  const [totpLoginStage, setTotpLoginStage] = useState("email");

  const [logInEmailSubmitting, setLogInEmailSubmitting] = useState(false);
  const [registerEmailSubmitting, setRegisterEmailSubmitting] = useState(false);

  const { data: session, status } = useSession();
  const router = useRouter();
  /*let mode =
    qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
      ignoreQueryPrefix: true,
    }).mode || "login"; */
  let callbackUrl = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).callbackUrl;
  let interval = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {}).interval;
  let lp = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {}).lp;
  if (interval) callbackUrl += "&interval=" + interval;
  if (lp) callbackUrl += "&lp=" + lp;

  let allQueryParams = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  });

  let landingPage = null;
  let waitinglistoff = null;
  let queryCode = null;
  let queryEmail = null;
  let mode = "login";

  if (allQueryParams.lp) landingPage = allQueryParams.lp;
  if (allQueryParams.waitinglistoff) waitinglistoff = allQueryParams.waitinglistoff;
  if (allQueryParams.mode) mode = allQueryParams.mode;

  const posthogSessionId = posthog.get_session_id();

  // login form
  const {
    register: registerLogin,
    //handleSubmit: handleSubmitLogin,
    getValues: getValuesLogin,
    setValue: setValueLogin,
    clearErrors: clearErrorsLogin,
    watch: watchLogin,
    formState: { errors: errorsLogin, isSubmitting: loginIsSubmitting },
  } = useForm();

  const onSubmitLogin = async (data) => {
    const signinResponse = await signIn("credentials-login", {
      username: data.username,
      password: data.password,
      redirect: false,
    });

    if (signinResponse?.error) setServerErrorLogin(signinResponse.error);
  };

  // signup form
  /*
  const {
    register: registerSignup,
    handleSubmit: handleSubmitSignup,
    getValues,
    watch: watchSignup,
    formState: { errors: errorsSignup },
  } = useForm();

  const onSubmitSignup = async (data) => {
    const signinResponse = await signIn("credentials-signup", {
      displayName: data.name,
      companyName: data.company,
      username: data.username,
      acceptTos: data.accepTos,
      waitingListOff: waitinglistoff,
      landingPage,
      redirect: false,
    });

    if (signinResponse?.error) setServerErrorSignup(signinResponse.error);


  }; */

  // handle totp login
  const {
    register: registerTotp,
    //handleSubmit: handleSubmitTotp,
    getValues: getValuesTotp,
    setValue: setValueTotp,
    watch: watchTotp,
    setError: setErrorTotp,
    clearErrors: clearErrorsTotp,
    formState: { errors: errorsTotp, isSubmitting: totpIsSubmitting },
  } = useForm();

  const onSubmitTotp = async (data) => {
    const signinResponse = await signIn("email-totp", {
      email: data.email,
      displayName: data.name,
      companyName: data.company,
      acceptTos: data.accepTos,
      waitingListOff: waitinglistoff,
      landingPage,
      totp: data.totp,
      redirect: false,
    });

    if (signinResponse?.error) setServerErrorTotp(signinResponse.error);
  };

  if (allQueryParams.code) queryCode = allQueryParams.code;
  if (allQueryParams.email) queryEmail = allQueryParams.email;

  useEffect(() => {
    if (queryCode) {
      setValueTotp("totp", queryCode);
      setTotpLoginStage("totp");
    }
    if (queryEmail) {
      setValueTotp("email", queryEmail.replaceAll("%2B", "+"));
    }
  }, [queryCode, queryEmail]);

  // define callback urls
  let newCallbackUrl = null;
  if (callbackUrl && accessToken) {
    // redirect to app.automate.video (or dev versions)
    let redirectUrl = callbackUrl.replace(config.appAddress, "");
    if (callbackUrl.startsWith(config.appAddress))
      newCallbackUrl = `${config.appAddress}/tokenlogin?accessToken=${accessToken}&redirect=${redirectUrl}`;
    // allow callbackUrl to new player
    else if (callbackUrl.startsWith(config.player)) newCallbackUrl = callbackUrl;
    // redirect to localhost:3000 if in callbackUrl
    else if (callbackUrl.startsWith("http://localhost:3000")) {
      let redirectUrl = callbackUrl.replace("http://localhost:3000", "");
      newCallbackUrl = `http://localhost:3000/tokenlogin?accessToken=${accessToken}&redirect=${redirectUrl}`;
      // else dismiss callbackUrl and redirect to uploads
    } else newCallbackUrl = `${config.appAddress}/tokenlogin?accessToken=${accessToken}&redirect=/uploads`;
  } else if (accessToken)
    // if no callbackUrl redirect to uploads
    newCallbackUrl = `${config.appAddress}/tokenlogin?accessToken=${accessToken}&redirect=/uploads`;

  // forward after successful login / registering with form
  if (newCallbackUrl && accessToken) {
    if (userid) {
      gtagRaw("event", "login", { user_id: userid.toString() });
      posthog.identify(userid.toString());
      posthog.capture("LOGIN", {});
    }

    if (typeof window !== "undefined") window.location.href = newCallbackUrl;

    return <div>Logging in...</div>;
  }

  if (status === "loading") return <div>Loading...</div>;

  const handleLogInWithEmail = async (event) => {
    event.preventDefault();
    setLogInEmailSubmitting(true);
    setServerErrorTotp(null);
    try {
      let url = process.env.NEXT_PUBLIC_API_URL + "/users/totp/sendemail";
      const email = getValuesTotp("email");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();

      if (result.error && result.reason === "no-user") {
        let query = {
          ...allQueryParams,
          mode: "register",
          info: "no-user",
          email: email, //.replace("+", "%2B"),
        };

        if (callbackUrl) query.callbackUrl = callbackUrl;
        router.push(
          {
            pathname: "/login",
            query,
          },
          undefined,
          { shallow: true }
        );
      } else {
        setTotpLoginStage("totp");
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      setServerErrorTotp("Failed to connect");
      setLogInEmailSubmitting(false);
    }
  };

  // direct to api server, replace nextauth
  const handleSubmitTotp = async (event) => {
    event.preventDefault();
    let url = process.env.NEXT_PUBLIC_API_URL + "/users/totp/loginwithtotp";
    const totp = getValuesTotp("totp");
    const email = getValuesTotp("email");

    const response = await fetch(url, {
      method: "POST",
      credentials: "include", // Important: Allow credentials/cookies to be sent/received
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, totp }),
    });
    const result = await response.json();

    if (result?.user?.accessToken && typeof window !== "undefined") {
      if (callbackUrl) {
        if (callbackUrl.startsWith(config.appAddress))
          window.location.href = `${config.appAddress}/tokenlogin?accessToken=${result.user.accessToken}&redirect=/uploads`;
        else window.location.href = callbackUrl;
      }

      window.location.href = `${config.appAddress}/tokenlogin?accessToken=${result.user.accessToken}&redirect=/uploads`;
    }
  };

  // direct to api server, replace nextauth
  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    let url = process.env.NEXT_PUBLIC_API_URL + "/users/login/username";
    const username = getValuesLogin("username");
    const password = getValuesLogin("password");

    const response = await fetch(url, {
      method: "POST",
      credentials: "include", // Important: Allow credentials/cookies to be sent/received
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const result = await response.json();

    if (result?.user?.accessToken) {
      if (callbackUrl && typeof window !== "undefined") {
        if (callbackUrl.startsWith(config.appAddress))
          window.location.href = `${config.appAddress}/tokenlogin?accessToken=${result.user.accessToken}&redirect=/uploads`;
        else window.location.href = callbackUrl;
      }
      if (typeof window !== "undefined")
        window.location.href = `${config.appAddress}/tokenlogin?accessToken=${result.user.accessToken}&redirect=/uploads`;
    }
  };

  return (
    <div className="flex justify-center items-center h-screen my-auto bg-gray-100">
      <Head>
        <title>Login | Automate.video</title>
      </Head>
      <div className="flex flex-col items-center bg-white max-w-screen-md shadow-lg rounded mx-auto px-24 sm:px-8 pt-6 pb-8 my-auto">
        <Link href="/">
          <img
            id="logo"
            className="self-start"
            src="/assets/automatevideologotransparent2.png"
            style={{
              maxHeight: 40,
              cursor: "pointer",
            }}
          />
        </Link>

        {mode === "register" && queryEmail && totpLoginStage === "email" && (
          <div className="text-sm mt-4 bg-slate-200 ">
            <div className="flex flex-row w-full justify-between">
              <div className="text-slate-700 p-2">No user with {queryEmail} was found. Please sign up instead.</div>
            </div>
          </div>
        )}

        <h1 className="text-2xl text-gray-500 font-bold  mt-3 mb-3">{mode === "register" ? "Sign up" : "Log in"}</h1>
        {loginDisabled ? (
          "We are updating our services, please come back later"
        ) : (
          <div>
            <div className="flex flex-row w-full flex-wrap gap-4 ">
              <div className="w-full sm:w-1/2 px-4 flex flex-col items-center">
                {Object.values(providers).map((provider) => {
                  if (provider.id === "email-totp" && mode === "login") {
                    return (
                      <div key={provider.name} className="w-full">
                        <div
                          className="w-full text-center border-b-2 my-4 text-sm text-gray-500"
                          style={{ lineHeight: "0.1em" }}
                        >
                          <span className="bg-white px-3">Log in with email and code</span>
                        </div>
                        <div className="flex flex-col">
                          {totpLoginStage === "email" && (
                            <form onSubmit={handleLogInWithEmail} className="w-full">
                              <div className="mb-2 w-full">
                                <label className="block text-gray-700 text-sm font-bold">
                                  Email address
                                  <input
                                    required
                                    type="text"
                                    autoComplete="username"
                                    className={errorsTotp.email ? textInputErrorStyle : textInputStyle}
                                    {...registerTotp("email", {
                                      required: true,
                                    })}
                                  />
                                </label>
                                <div className="h-3 text-sm text-orange-dark">
                                  {errorsTotp.username && <span>Please enter valid email address </span>}
                                </div>
                              </div>
                              <div className="mt-2 mb-1">
                                <button
                                  disabled={logInEmailSubmitting}
                                  type="submit"
                                  className="w-full h-10 bg-blue-500 disabled:bg-gray-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                  Log in with Email
                                </button>
                                {serverErrorTotp && (
                                  <span className="font-bold text-sm text-orange-dark">
                                    Did not succeed. Please try again.
                                  </span>
                                )}
                              </div>
                              <div className="flex flex-col w-full  ">
                                <div className="flex flex-row w-full justify-between">
                                  <div className="text-slate-700  text-center text-sm ">
                                    <span className="">We'll email you a code for password-free sign in. </span>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="w-full text-sm bg-white border border-slate-400 border-solid hover:bg-slate-200 text-slate-400 font-bold py-2 my-4 px-4 rounded focus:outline-none focus:shadow-outline"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    clearErrorsLogin();

                                    let query = {
                                      ...allQueryParams,
                                      mode: "password",
                                    };

                                    if (callbackUrl) query.callbackUrl = callbackUrl;
                                    router.push(
                                      {
                                        pathname: "/login",
                                        query,
                                      },
                                      undefined,
                                      { shallow: true }
                                    );
                                  }}
                                >
                                  Sign in with password instead
                                </button>
                              </div>
                            </form>
                          )}
                          {totpLoginStage === "totp" && (
                            <form onSubmit={handleSubmitTotp} className="w-full">
                              <input name="csrfToken" type="hidden" defaultValue={csrfToken} />

                              <div className="">
                                <label className="block">
                                  <div className="flex flex-row justify-between">
                                    <span className="text-gray-700 text-sm font-bold">Code</span>
                                    {(serverErrorTotp === "wrong-totp" || errorsTotp.totp) && (
                                      <span className="font-bold text-sm text-orange-dark">Code is not valid. </span>
                                    )}
                                    {serverErrorTotp && serverErrorTotp !== "wrong-totp" && (
                                      <span className="font-bold text-sm text-orange-dark">Did not succeed. </span>
                                    )}
                                  </div>
                                  <input
                                    onChange={() => setServerErrorTotp(null)}
                                    autoComplete=""
                                    type="text"
                                    className={errorsTotp.totp ? textInputErrorStyle : textInputStyle}
                                    {...registerTotp("totp", {
                                      required: true,
                                      minLength: 6,
                                      onChange: () => setServerErrorTotp(null),
                                    })}
                                  />
                                </label>
                                <div className="text-sm ">
                                  <div className="flex flex-col w-full justify-between">
                                    <div className="text-slate-400 italic">
                                      {<span>Code was sent to {getValuesTotp("email")}</span>}
                                    </div>
                                    <div className=" text-slate-400 italic">
                                      <span className="">Wrong email or email didn't arrive? </span>
                                      <span
                                        onClick={() => {
                                          setLogInEmailSubmitting(false);
                                          setTotpLoginStage("email");
                                        }}
                                        className="font-bold cursor-pointer"
                                      >
                                        Try again.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-4 mb-6">
                                <button
                                  disabled={totpIsSubmitting}
                                  type="submit"
                                  className="w-full h-10 bg-blue-500 disabled:bg-gray-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                  Verify
                                </button>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    );
                  } else if (provider.id === "email-totp" && mode === "register") {
                    return (
                      <div key={provider.name} className="w-full">
                        <div
                          className="w-full text-center border-b-2 my-4 text-sm text-gray-500"
                          style={{ lineHeight: "0.1em" }}
                        >
                          <span className="bg-white px-3">Sign up with email</span>
                        </div>
                        <div className="flex flex-row">
                          <form onSubmit={handleSubmitTotp} className="w-full">
                            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />

                            {totpLoginStage === "email" && (
                              <React.Fragment>
                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Name
                                    <input
                                      type="text"
                                      className={errorsTotp.name ? textInputErrorStyle : textInputStyle}
                                      {...registerTotp("name")}
                                    />
                                  </label>
                                </div>
                                <div className="mb-4">
                                  <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Company
                                    <input
                                      type="text"
                                      className={errorsTotp.company ? textInputErrorStyle : textInputStyle}
                                      {...registerTotp("company")}
                                    />
                                  </label>
                                </div>
                                <div className="mb-4 w-full">
                                  <label className="block text-gray-700 text-sm font-bold">
                                    Email address
                                    <input
                                      type="text"
                                      autoComplete="username"
                                      className={errorsTotp.email ? textInputErrorStyle : textInputStyle}
                                      {...registerTotp("email", {
                                        required: true,
                                        validate: (email) => {
                                          return validateEmail(email) || "Check your email address";
                                        },
                                      })}
                                    />
                                  </label>
                                  <div className="h-3 text-sm text-orange-dark">
                                    {errorsTotp.username && <span>Please enter valid email address </span>}
                                  </div>
                                </div>
                                <div className="">
                                  <label className="inline-flex items-center">
                                    <input
                                      type="checkbox"
                                      className="form-checkbox mr-2"
                                      name="acceptTos"
                                      value="true"
                                      {...registerTotp("acceptTos", { required: true })}
                                    />
                                    <div className="text-sm">
                                      I understand and agree to{" "}
                                      <a target="_blank" href="/terms/privacy-policy.html">
                                        <span style={{ textDecoration: "underline" }}>Privacy Policy</span>
                                      </a>{" "}
                                      and{" "}
                                      <a target="_blank" href="/terms/terms-of-service.html">
                                        <span style={{ textDecoration: "underline" }}>Terms of Service</span>
                                      </a>
                                    </div>
                                  </label>
                                  <div className="h-3 text-sm text-orange-dark">
                                    {errorsTotp.acceptTos && <span>Please accept our terms</span>}
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {totpLoginStage === "totp" && (
                              <div className="">
                                <label className="block">
                                  <div className="flex flex-row justify-between">
                                    <span className="text-gray-700 text-sm font-bold">Code</span>
                                    {(serverErrorTotp === "wrong-totp" || errorsTotp.totp) && (
                                      <span className="font-bold text-sm text-orange-dark">Code is not valid. </span>
                                    )}
                                    {serverErrorTotp && serverErrorTotp !== "wrong-totp" && (
                                      <span className="font-bold text-sm text-orange-dark">Did not succeed. </span>
                                    )}
                                  </div>
                                  <input
                                    autoComplete=""
                                    type="text"
                                    className={errorsTotp.totp ? textInputErrorStyle : textInputStyle}
                                    {...registerTotp("totp", { required: true, minLength: 6 })}
                                  />
                                </label>
                                <div className="text-sm ">
                                  <div className="flex flex-col w-full justify-between">
                                    <div className="text-slate-400 italic">
                                      {<span>Code was sent to {getValuesTotp("email")}</span>}
                                    </div>
                                    <div className=" ">
                                      <span className="cursor-pointer text-slate-400 italic">
                                        Wrong email or email didn't arrive?{" "}
                                        <span
                                          onClick={() => {
                                            setRegisterEmailSubmitting(false);
                                            setTotpLoginStage("email");
                                          }}
                                          className="cursor-pointer font-bold"
                                        >
                                          Try again.
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {totpLoginStage === "email" && (
                              <div className="my-3">
                                <button
                                  disabled={registerEmailSubmitting}
                                  type="button"
                                  className="w-full h-10 bg-blue-500 disabled:bg-gray-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    clearErrorsTotp();
                                    setRegisterEmailSubmitting(true);
                                    setServerErrorTotp(null);
                                    try {
                                      let url = process.env.NEXT_PUBLIC_API_URL + "/users/totp/sendemail";
                                      const email = getValuesTotp("email");
                                      const acceptTos = getValuesTotp("acceptTos");
                                      const displayName = getValuesTotp("name");
                                      const companyName = getValuesTotp("company");

                                      if (!acceptTos) {
                                        setErrorTotp("acceptTos");
                                        setRegisterEmailSubmitting(false);
                                        return;
                                      }

                                      const response = await fetch(url, {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({ email, acceptTos, displayName, companyName }),
                                      });
                                      const result = await response.json();

                                      if (result.error && result.reason === "no-user") {
                                        let query = {
                                          ...allQueryParams,
                                        };

                                        if (callbackUrl) query.callbackUrl = callbackUrl;
                                        router.push(
                                          {
                                            pathname: "/login",
                                            query,
                                          },
                                          undefined,
                                          { shallow: true }
                                        );
                                      } else {
                                        setTotpLoginStage("totp");
                                      }
                                    } catch (err) {
                                      console.error(err);
                                      Sentry.captureException(err);
                                      setServerErrorTotp("Failed to connect");
                                      setRegisterEmailSubmitting(false);
                                    }
                                  }}
                                >
                                  Sign up with Email
                                </button>
                                {serverErrorTotp && (
                                  <span className="font-bold text-sm text-orange-dark">
                                    Did not succeed. Please try again.
                                  </span>
                                )}
                              </div>
                            )}

                            {totpLoginStage === "totp" && (
                              <div className="mt-4 mb-6">
                                <button
                                  disabled={totpIsSubmitting}
                                  type="submit"
                                  className="w-full h-10 bg-blue-500 disabled:bg-gray-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                  Verify
                                </button>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    );
                  } else if (provider.id === "credentials-login" && mode === "password") {
                    return (
                      <div key={provider.name} className="w-full">
                        <div
                          className="w-full text-center border-b-2 my-4 text-sm text-gray-500"
                          style={{ lineHeight: "0.1em" }}
                        >
                          <span className="bg-white px-3">Log in with email and password</span>
                        </div>
                        <div className="flex flex-row">
                          <form onSubmit={handleSubmitLogin} className="flex flex-col w-full">
                            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
                            <div className="mb-2 w-full">
                              <label className="block text-gray-700 text-sm font-bold">
                                Email address
                                <input
                                  type="text"
                                  autoComplete="username"
                                  className={errorsLogin.username ? textInputErrorStyle : textInputStyle}
                                  {...registerLogin("username", {
                                    required: true,
                                  })}
                                />
                              </label>
                              <div className="h-3 text-sm text-orange-dark">
                                {errorsLogin.username && <span>Please enter valid email address </span>}
                              </div>
                            </div>
                            <div className="mb-2">
                              <label className="block text-gray-700 text-sm font-bold ">
                                Password
                                <input
                                  autoComplete="current-password"
                                  type="password"
                                  className={errorsLogin.password ? textInputErrorStyle : textInputStyle}
                                  {...registerLogin("password", { required: true })}
                                />
                              </label>
                              <div className="text-sm text-orange-dark">
                                <div className="flex flex-col w-full justify-between">
                                  <div className="">
                                    {errorsLogin.password && <span>Please enter your password</span>}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="block mb-2 text-sm text-orange-dark">
                              {serverErrorLogin && <span>Please check your email and password</span>}
                            </div>

                            <button
                              type="submit"
                              disabled={loginIsSubmitting}
                              className="w-full h-10 bg-blue-500 disabled:bg-gray-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                              Log in
                            </button>

                            <button
                              className="w-full text-sm bg-white border border-slate-400 border-solid hover:bg-slate-200 text-slate-400 font-bold py-2 my-4 px-4 rounded focus:outline-none focus:shadow-outline"
                              onClick={(event) => {
                                event.preventDefault();
                                setLogInEmailSubmitting(false);
                                let query = {
                                  ...allQueryParams,
                                  mode: "login",
                                };

                                if (callbackUrl) query.callbackUrl = callbackUrl;
                                router.push(
                                  {
                                    pathname: "/login",
                                    query,
                                  },
                                  undefined,
                                  { shallow: true }
                                );
                              }}
                            >
                              Log in with email link instead
                            </button>
                          </form>
                        </div>
                      </div>
                    );
                  } else if (false && provider.id === "credentials-signup" && mode === "register") {
                    /*
                    return (
                      <div key={provider.name}>
                        <div
                          className="w-full text-center border-b-2 my-4 text-sm text-gray-500"
                          style={{ lineHeight: "0.1em" }}
                        >
                          <span className="bg-white px-3">Or sign up with email</span>
                        </div>
                        <div className="flex flex-row w-full">
                          <form onSubmit={handleSubmitSignup(onSubmitSignup)} className="">
                            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2">
                                Name
                                <input
                                  type="text"
                                  className={errorsSignup.name ? textInputErrorStyle : textInputStyle}
                                  {...registerSignup("name")}
                                />
                              </label>
                            </div>
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2">
                                Company
                                <input
                                  type="text"
                                  className={errorsSignup.company ? textInputErrorStyle : textInputStyle}
                                  {...registerSignup("company")}
                                />
                              </label>
                            </div>
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold">
                                Email address
                                <input
                                  autoComplete="username"
                                  type="text"
                                  className={errorsSignup.username ? textInputErrorStyle : textInputStyle}
                                  {...registerSignup("username", {
                                    required: true,
                                    validate: (username) => {
                                      return validateEmail(username) || "Check your email address";
                                    },
                                  })}
                                />
                              </label>
                              <div className="h-3 text-sm text-orange-dark">
                                {errorsSignup.username && <span>{errorsSignup.username.message}</span>}
                              </div>
                            </div>

                            <div className="">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="form-checkbox mr-2"
                                  name="acceptTos"
                                  value="true"
                                  {...registerSignup("acceptTos", { required: true })}
                                />
                                <div className="text-sm">
                                  I understand and agree to{" "}
                                  <a target="_blank" href="/terms/privacy-policy.html">
                                    <span style={{ textDecoration: "underline" }}>Privacy Policy</span>
                                  </a>{" "}
                                  and{" "}
                                  <a target="_blank" href="/terms/terms-of-service.html">
                                    <span style={{ textDecoration: "underline" }}>Terms of Service</span>
                                  </a>
                                </div>
                              </label>
                              <div className="h-3 text-sm text-orange-dark">
                                {errorsSignup.acceptTos && <span>Please accept our terms</span>}
                              </div>
                            </div>
                            <div className="h-3 block mb-4 text-sm text-orange-dark">
                              {serverErrorSignup === "user-exists" && (
                                <span>User already exists, please login instead</span>
                              )}
                              {serverErrorSignup === "signup-failed" && <span>Signup failed</span>}
                              {serverErrorSignup === "added-to-waitlist" && (
                                <span>You have been added to the waitlist</span>
                              )}
                              {serverErrorSignup === "updating-services" && (
                                <span>We are updating our services, please try again later.</span>
                              )}
                            </div>
                            <div className="my-3">
                              <button
                                type="submit"
                                className="w-full h-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              >
                                Signup
                              </button>
                            </div>
                            <button
                              className="block w-full text-gray-500"
                              onClick={(event) => {
                                event.preventDefault();
                                let query = {
                                  ...allQueryParams,
                                  mode: "login",
                                };

                                if (callbackUrl) query.callbackUrl = callbackUrl;
                                router.push(
                                  {
                                    pathname: "/login",
                                    query,
                                  },
                                  undefined,
                                  { shallow: true }
                                );
                              }}
                            >
                              or click here to log in
                            </button>
                          </form>
                        </div>
                      </div>
                    ); */
                  }
                })}
              </div>
              <div className="w-full sm:w-2/5 px-4 flex flex-col items-center order-first">
                <div
                  className="w-full text-center border-b-2 my-4 text-sm text-gray-500"
                  style={{ lineHeight: "0.1em" }}
                >
                  <span className="bg-white px-3">Social{mode === "register" ? " sign up" : " log in "}</span>
                </div>
                <div className="">
                  {Object.values(providers).map((provider) => {
                    if (provider.id === "google" || provider.id === "facebook" || provider.id === "twitter") {
                      let providerName = provider.name;
                      if (provider.id === "twitter") providerName = "Twitter";
                      return (
                        <div key={provider.name} className="my-3">
                          <SocialLoginButton
                            onClick={() =>
                              signIn(provider.id, {
                                callbackUrl: newCallbackUrl,
                                posthogSessionId,
                              })
                            }
                            provider={provider.id}
                            buttonText={`${mode === "register" ? "Sign up" : "Log in"} with ${providerName}`}
                          />
                        </div>
                      );
                    } else if (
                      provider.id !== "credentials-signup" &&
                      provider.id !== "credentials-login" &&
                      provider.id !== "email-totp"
                    )
                      return (
                        <div key={provider.name}>
                          <button onClick={() => signIn(provider.id)}>Sign in with {provider.name}</button>
                        </div>
                      );
                  })}
                </div>
              </div>

              <div className="w-full flex flex-col items-center">
                {" "}
                {(mode === "login" || mode === "password") && (
                  <button
                    className="block w-full text-gray-500"
                    onClick={(event) => {
                      event.preventDefault();
                      setRegisterEmailSubmitting(false);
                      setTotpLoginStage("email");
                      setServerErrorTotp(false);

                      let query = {
                        ...allQueryParams,
                        mode: "register",
                      };
                      if (process.env.NEXT_PUBLIC_AUTOMATEVIDEO_ENV === "development" && waitinglistoff)
                        query.waitinglistoff = "true";
                      if (callbackUrl) query.callbackUrl = callbackUrl;
                      router.push(
                        {
                          pathname: "/login",
                          query,
                        },
                        undefined,
                        { shallow: true }
                      );
                    }}
                  >
                    if you don't have an account, you can<span className="font-bold"> click here to sign up</span>
                  </button>
                )}
                {mode === "register" && (
                  <button
                    className="block w-full text-gray-500"
                    onClick={(event) => {
                      event.preventDefault();
                      setLogInEmailSubmitting(false);
                      setTotpLoginStage("email");
                      setServerErrorTotp(false);
                      let query = {
                        ...allQueryParams,
                        mode: "login",
                      };

                      if (callbackUrl) query.callbackUrl = callbackUrl;
                      router.push(
                        {
                          pathname: "/login",
                          query,
                        },
                        undefined,
                        { shallow: true }
                      );
                    }}
                  >
                    or click here to log in
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default signin;

export async function getServerSideProps(context) {
  const { req, query, res } = context;

  const cookies = parse(req.headers.cookie || "");
  let accessToken = cookies.accessToken ?? null;
  let userid = null;
  if (accessToken) {
    const res = await fetch(`${config.socketIo}/users/login/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accessToken }),
    });

    let data = await res.json();
    if (data?.error) {
      console.log("accessToken is not valid anymore");
      const stage = process.env.NEXT_APP_STAGE || "production";

      // Delete the accessToken cookie
      const serializedCookie = serialize("accessToken", "", {
        httpOnly: false, //true,
        path: "/",
        maxAge: 0,
        expires: new Date(0),
        sameSite: "lax",
        secure: stage === "local" ? false : true, // MATCH your original cookie settings!
        domain: stage === "local" ? "localhost" : ".automate.video",
      });

      // Set the 'Set-Cookie' header in the *response object within the returned object*
      res.setHeader("Set-Cookie", serializedCookie);
      accessToken = null;
    } else {
      userid = data?.user?.id;
    }
  }

  // Get the hostname from the request headers
  const hostname = req.headers.host;

  // Decide whether to redirect based on hostname
  if (hostname === "dev.automate.video" || hostname === "automate.video") {
    // Build the query string from the original request
    const queryString = new URLSearchParams(query).toString();

    let domain = "https://login.automate.video/login";
    if (process.env.NEXT_APP_NODE_ENV === "development") domain = "https://dev-login.automate.video/login";

    // Create the target URL with the query parameters
    const targetUrl = `${domain}${queryString.length ? `?${queryString}` : ""}`;

    return {
      redirect: {
        destination: targetUrl,
        permanent: false, // Set to true for a 301 redirect, false for a 302 redirect
      },
    };
  }

  const session = await getSession({ req });

  /*
  if (session?.user?.id) {
    return {
      redirect: { destination: `${config.appAddress}/tokenlogin?accessToken=${session.accessToken}&redirect=/uploads` },
    };
  } */
  let loginDisabled = false;
  if (process.env.LOGIN_DISABLED === "true") {
    loginDisabled = true;
    console.log("login disabled", process.env.LOGIN_DISABLED);
  }
  return {
    props: {
      providers: await getProviders(context),
      csrfToken: await getCsrfToken(context),
      loginDisabled,
      accessToken,
      userid,
    },
  };
}

function validateEmail(email) {
  var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email);
}
